import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseWork from '../components/course/courseWork';

const Work = () => {
  return (
    <>
      <SEO title="Work" />
      <Layout>
        <CourseWork />
      </Layout>
    </>
  );
};

export default Work;
