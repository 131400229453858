import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroWork from '../image-components/hero/heroWork';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-work.png';

const CourseWork = () => {
  return (
    <>
      <HeroWork />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>WORK ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving our Work via the Word of God.</div>
              <p>
                Work Advance is the space to study why we work, and how to serve
                God and our world through our occupations and vocations. Most of
                our time during the week is spent at our workplaces—toiling and
                creating and building. But as followers of Jesus, we yearn for a
                deeper meaning behind what we put our hands and minds to. That's
                why Work Advance was created—to help imbue a sense of meaning
                and purpose to our everyday work, with the return of Jesus in
                mind.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "All our work in the field, in the garden, in the city, in the
              home, in struggle, in government—to what does it all amount before
              God except child's play, by means of which God is pleased to give
              his gifts in the field, at home, and everywhere? These are the
              masks of our Lord God, behind which he wants to be hidden and to
              do all things."
            </div>
            <div className={Styles.author}>MARTIN LUTHER</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Participants will gain a biblical framework and
                  understanding of work and vocation.
                </li>
                <li>
                  Participants will understand what impact their day-to-day work
                  will have on eternity and when heaven comes to earth.
                </li>
                <li>
                  Participants will work on developing their individual
                  vocational credos and their unique calling and purpose in
                  life.
                </li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Work Advance organizes participants to journey together
                through the entire program. In the duration of six weeks,
                participants’ primary learning and reflection come from their
                willingness to be vulnerable and open in their stories and
                discoveries to their groups. In so doing, they learn largely
                from each other, helping one another discern purpose and meaning
                in their individual work scenarios and how it fits in the final
                eschatological picture upon Jesus' return.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Work Advance is paired with an advisor (TJ Moon) and an
                assistant who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Work Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship and the dynamic
                interaction, which will enrich and deepen its outcome. The
                format will comprise of weekly discussions online.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>The Origins of Calling</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>The Hope of Calling</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>The Context of Calling</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>The Purpose of Calling</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  The Discernment Needed for Calling
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>
                  The Fulfillment of Calling
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor TJ"
              />
              <div className={Styles.guide_name}>TJ Moon</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor of Ekko Church</div>
                <div>Work Advance Guide</div>
              </div>
              <p>
                Pastor TJ leads IKON (Men's Ministry). Along with pastoring, he
                is busy working to improve water quality in Los Angeles, raising
                his family, taking his wife on memorable dates, and mentoring
                young men at Ekko Church.
              </p>
              <p>
                He is currently employed at LA County Public Works and works to
                improve beach water quality. He received his Bachelor of Science
                degree (Civil Engineering) from UCLA, Master of Science degree
                (Civil Engineering) from Cal State LA, and his Master of
                Divinity from the King's University in Los Angeles. As you can
                tell, he lives, studies, and serves Los Angeles.  He is married
                to his "love at first sight," Joanne, is the father of the most
                active son, Jio, his second calm, soothing son Max, and his
                sweet daughter Joy.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseWork;
